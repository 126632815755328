import { FONT_BASE, COLOR_SECONDARY, TEXT_COLOR } from 'constants/theme';

import * as React from 'react';
import CSS from 'csstype';
import styled, { css } from 'styled-components';
import Icon from 'images/icons/icon-plus.svg';

interface P {
  isOpen: boolean;
}

const buttonHover = css`
  &:hover {
    transform: scale(1.2);
  }
`;

const ButtonBase = styled.button`
  width: ${FONT_BASE * 2}px;
  height: ${FONT_BASE * 2}px;
  background-color: ${(p: P) => (p.isOpen ? TEXT_COLOR : COLOR_SECONDARY)};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: 150ms ease-in-out;

  ${(p: P) => !p.isOpen && buttonHover}

  & svg {
    transition: 150ms ease-in-out;
    transform: rotate(${(p: P) => (p.isOpen ? 45 : 0)}deg);
  }
`;

interface ButtonProps {
  style?: CSS.Properties;
  className?: string;
  isOpen: boolean;
  onClick: EventHandler;
}

const Button = React.forwardRef<any, ButtonProps>(function Button(
  { style, className, isOpen, onClick },
  ref
) {
  return (
    <ButtonBase
      ref={ref}
      style={style}
      className={className}
      isOpen={isOpen}
      onClick={onClick}
      aria-label="Share article">
      <Icon />
    </ButtonBase>
  );
});

export default Button;
