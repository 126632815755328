import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { AnimatePresence } from 'framer-motion';
import { useLayer } from 'react-laag';
import Facebook from 'images/social/icon-facebook.svg';
import Twitter from 'images/social/icon-twitter.svg';
import LinkedIn from 'images/social/icon-in.svg';
import Google from 'images/social/icon-google.svg';
import Pinterest from 'images/social/icon-pinterest.svg';
import Mail from 'images/social/icon-mail.svg';

import Button from './button';
import Menu from './menu';

import './share.scss';

interface Props {
  path: string;
  title: string;
}

const Share: React.FC<Props> = ({ title, path }) => {
  const uri = encodeURI(path);
  const utm = `?utm_source=website&utm_campaign=share+article`;
  const linkShare = `mailto:?subject=${title}&body=Check%20out%20this%20great%20article%20I%20read:%0D%0A%0D%0A"${title}"%0D%0A${uri}${utm}&utm_medium=email`;
  const fbShare = `https://www.facebook.com/sharer/sharer.php?t=${title}&u=${uri}`;
  const twShare = `https://twitter.com/intent/tweet?text=${title}&url=${uri}&via=finalangel&hashtags=develop,study,teach`;
  const lnShare = `https://www.linkedin.com/shareArticle?mini=true&url=${uri}&title=${title}${utm}&utm_medium=linkedin`;
  const gShare = `https://www.youtube.com/watch?v=dQw4w9WgXcQ`;
  const pShare = `https://pinterest.com/pin/create/button/?description=${title}&url=${uri}${utm}&utm_medium=pinterest`;

  const [isOpen, setOpen] = React.useState(false);
  const { layerProps, triggerProps, renderLayer } = useLayer({
    isOpen,
    ResizeObserver,
    placement: 'center',
  });

  return (
    <div className="share">
      <Button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        isOpen={isOpen}
        aria-label="Share on"
      />

      {isOpen &&
        renderLayer(
          <AnimatePresence>
            <Menu
              {...layerProps}
              close={close}
              items={[
                {
                  Icon: Mail,
                  value: linkShare,
                  label: 'Email',
                },
                {
                  Icon: Facebook,
                  value: fbShare,
                  label: 'Facebook',
                },
                {
                  Icon: Twitter,
                  value: twShare,
                  label: 'Twitter',
                },
                {
                  Icon: LinkedIn,
                  value: lnShare,
                  label: 'LinkedIn',
                },
                {
                  Icon: Google,
                  value: gShare,
                  label: 'Google +',
                },
                {
                  Icon: Pinterest,
                  value: pShare,
                  label: 'Pinterest',
                },
              ]}
            />
          </AnimatePresence>
        )}
    </div>
  );
};

export default Share;
