// currently sadly a duplicate from "components/blog/index.ts"
function getBlogURL(url) {
  // replace /2020-04-08_new-beginnings/
  // to /blog/new-beginnings/ as slug
  return url.replace(/^[^_]*_/, '/blog/');
}

module.exports = {
  getBlogURL,
};
