import React, { useEffect, useState } from 'react';

import './reading-position.scss';

interface Props {
  target: any;
}

const ReadingProgress: React.FC<Props> = ({ target }) => {
  const [isSticky, setSticky] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element = target.current;
    const totalHeight = window.document.body.offsetHeight - window.innerHeight;
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (windowScrollTop === 0) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100);
    }

    setSticky(element.getBoundingClientRect().top <= 0);
    setReadingProgress((windowScrollTop / totalHeight) * 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  });

  return (
    <div
      className={`reading-progress-bar${
        isSticky ? ' reading-progress-bar--sticky' : ''
      }`}
      style={{ width: `${readingProgress}%` }}
    />
  );
};

export default ReadingProgress;
