import { BULL } from 'constants/theme';

import React, { createRef } from 'react';
import { Link, graphql } from 'gatsby';
import readingTime from 'reading-time';

import Head from 'components/layout/head';
import Layout from 'components/layout';
import { Row, Column } from 'components/grid';
import MoreLink from 'components/moreLink/moreLink';
import Share from 'components/blog/share';
import { getBlogURL } from 'components/blog/utils';
import ReadingProgress from 'components/readingPosition';
import Commento from 'components/commento';
import Spacer from 'components/spacer/spacer';

import './blog.scss';
import './blog-prism.scss';

interface Props {
  data: any;
  pageContext: any;
  location: any;
}

const BlogPostTemplate: React.FC<Props> = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;
  const path = location.href;
  const target = createRef<HTMLDivElement>();

  return (
    <Layout dark={true}>
      <Head
        pageTitle={post.frontmatter.title}
        imageUrl={
          post.frontmatter.cover.childImageSharp.gatsbyImageData.images.fallback
            ?.src
        }
        siteDescription={post.frontmatter.description}
      />

      <Row tag="header" className="main__header blog--sticky">
        <Column>
          <h1 className="text-center">{post.frontmatter.title}</h1>
          <p className="lead text-secondary text-center">
            {post.frontmatter.date} {BULL}{' '}
            {readingTime(post.rawMarkdownBody).minutes} min read {BULL}{' '}
            {post.frontmatter.category}
          </p>
        </Column>
      </Row>

      <section ref={target}>
        <Row style={{ position: 'relative ' }}>
          <ReadingProgress target={target} />
          <Column className="boxed-content blog">
            <Share title={post.frontmatter.title} path={path} />
            <section dangerouslySetInnerHTML={{ __html: post.html }}></section>
            <hr />
            <Commento id={post.fields.slug} pageId={post.id} noFonts={true} />
          </Column>
        </Row>
      </section>

      <Row tag="footer" className="main__footer main__footer--blog">
        <Column>
          <nav className="blog-nav">
            {previous && (
              <Link
                to={getBlogURL(previous.fields.slug)}
                className="blog-nav__anchor blog-nav__anchor--prev"
                rel="prev">
                &#8610; {previous.frontmatter.title}
              </Link>
            )}
            {next && (
              <Link
                to={getBlogURL(next.fields.slug)}
                className="blog-nav__anchor blog-nav__anchor--next"
                rel="next">
                {next.frontmatter.title} &#8611;
              </Link>
            )}
          </nav>
          <MoreLink to="/blog/" text="Back to Overview" style="secondary" />
        </Column>
      </Row>

      <Spacer />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteTitleShort
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      rawMarkdownBody
      frontmatter {
        title
        date(formatString: "DD. MMMM, YYYY")
        description
        category
        cover {
          childImageSharp {
            gatsbyImageData(width: 960, layout: FIXED)
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
