import * as theme from 'constants/theme';

import React from 'react';
import { Link } from 'gatsby';

import './morelink.scss';

interface Props {
  to: string;
  text: string;
  style: string;
  onClick?: EventHandler;
}

const MoreLink: React.FC<Props> = ({ style, to, text, ...rest }) => {
  let color = '';

  switch (style) {
    case 'primary':
      color = theme.COLOR_PRIMARY;
      break;
    case 'secondary':
      color = theme.COLOR_SECONDARY;
      break;
    case 'muted':
      color = theme.COLOR_MUTED;
      break;
    case 'success':
      color = theme.COLOR_SUCCESS;
      break;
    default:
      color = theme.TEXT_COLOR;
  }

  const circles = (
    <svg width="26" height="26" viewBox="0 0 26 26">
      <circle cx="6" cy="13" r="2.25" stroke={color} strokeWidth="1.5" />
      <circle cx="13" cy="13" r="2.25" stroke={color} strokeWidth="1.5" />
      <circle cx="20" cy="13" r="2.25" stroke={color} strokeWidth="1.5" />
    </svg>
  );

  return (
    <p className="text-center">
      <Link to={to} className="link__more" {...rest}>
        <span>{text}</span>
        {circles}
      </Link>
    </p>
  );
};

export default MoreLink;
