import {
  FONT_BASE,
  TEXT_COLOR,
  COLOR_SECONDARY,
  BG_SECONDARY,
} from 'constants/theme';

import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

/**
 * Positioning Stuff
 */
function getTransform(
  progress: number,
  radius: number,
  index: number,
  totalItems: number
): string {
  const value = (index / totalItems) * progress;

  const x = radius * Math.cos(Math.PI * 2 * (value - 0.25));
  const y = radius * Math.sin(Math.PI * 2 * (value - 0.25));

  const scale = progress / 2 + 0.5;

  return `translate(${x}px, ${y}px) scale(${scale})`;
}

/**
 * MenuItem
 */
const Circle = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${FONT_BASE * 1.5}px;
  height: ${FONT_BASE * 1.5}px;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  will-change: transform;
  background-color: ${COLOR_SECONDARY};

  path {
    fill: ${BG_SECONDARY};
  }

  svg {
    transition: 150ms ease-in-out;
  }

  &:hover {
    background-color: ${BG_SECONDARY};

    svg {
      transform: scale(1.7);
    }

    path {
      fill: ${TEXT_COLOR};
    }
  }
`;

interface MenuItemProps {
  style?: any;
  className?: any;
  Icon: any;
  onClick: any;
  label: any;
  index: any;
  totalItems: any;
}

const MenuItem: React.FC<MenuItemProps> = ({
  style,
  className,
  Icon,
  onClick,
  label,
  index,
  totalItems,
}) => {
  return (
    <Circle
      className={className}
      style={style}
      onClick={onClick}
      title={label}
      initial={{ x: 0, opacity: 0 }}
      animate={{ x: 1, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transformTemplate={({ x }: { x: string }) => {
        const value = parseFloat(x.replace('px', ''));

        return getTransform(value, FONT_BASE * 2.2, index, totalItems);
      }}
      transition={{
        delay: index * 0.025,
        type: 'spring',
        stiffness: 600,
        damping: 50,
        mass: 1,
      }}>
      {React.createElement(Icon, { size: 20 })}
    </Circle>
  );
};

/**
 * Menu
 */
const MenuBase: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${FONT_BASE * 4}px;
  height: ${FONT_BASE * 4}px;
  pointer-events: none;
  border-radius: 50%;
`;

interface ItemProps {
  Icon: any;
  value: string;
  label: string;
}

interface MenuProps {
  items: ItemProps[];
  close: any;
  style?: unknown;
}

const Menu = React.forwardRef<any, MenuProps>(function Menu(
  { items, close, style },
  ref
) {
  return (
    <MenuBase ref={ref} style={style} onClick={close}>
      {items.map((item: ItemProps, index: number) => (
        <MenuItem
          key={index}
          Icon={item.Icon}
          label={item.label}
          onClick={() =>
            typeof window !== 'undefined'
              ? window.open(item.value, '_blank')
              : null
          }
          index={index}
          totalItems={items.length}
        />
      ))}
    </MenuBase>
  );
});

export default Menu;
